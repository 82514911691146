
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import printJs from 'print-js';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { arabicFont } from '@/assets/ts/arabic-font';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';
  import moment from 'moment';

  export default defineComponent({
    name: 'customer-account-report',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const font = arabicFont;
      const reportData = ref();
      const selectedCustomer = ref<number>(0);
      const selectedTrainer = ref<number>(0);
      const selectedHorse = ref<number>(0);
      const selectedArena = ref<number>(0);
      const selectedAttendance = ref<number>(0);
      const selecteDuration = ref<number>(0);
      const selecteType = ref<number>(0);
      const selectedDay = ref<number>(0);
      const selectedGroomer = ref<number>(0);
      const isPaid = ref<boolean>(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const startDate = ref(moment().startOf('day')._d);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const endDate = ref(moment().startOf('day')._d);
      const balanceTo = ref();
      const balanceFrom = ref();
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('CustomerAccountReport'), [
          translate('reports'),
        ]);
        store.dispatch(Actions.GET_ALL_CUSTOMERS);
        store.dispatch(Actions.GET_ALL_TRAINERS, {});
        store.dispatch(Actions.GET_ALL_ARENAS);
        store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS);
        const getSaveData = searchService.getSearchValue(
          'CustomerAccountReport'
        );
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(searchService.getSearchValue('CustomerAccountReport'))
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.startDate) startDate.value = dataSaved.startDate;
          if (dataSaved.endDate) endDate.value = dataSaved.endDate;
          if (dataSaved.isPaid) isPaid.value = dataSaved.isPaid;
          if (dataSaved.customerId)
            selectedCustomer.value = dataSaved.customerId;

          if (dataSaved.balanceFrom) balanceFrom.value = dataSaved.balanceFrom;
          if (dataSaved.balanceTo) balanceTo.value = dataSaved.balanceTo;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('CustomerAccountReport');
        selectedCustomer.value = 0;
        isPaid.value = false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        startDate.value = moment().startOf('day')._d;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        endDate.value = moment().startOf('day')._d;
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

        values.startDate = moment(values.startDate).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );
        values.endDate = moment(values.endDate).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );

        const user = getUser();
        const userName = user ? user['name'] : '';

        if (isPaid.value) values.isPaid = isPaid.value;
        searchService.setSearchValue('CustomerAccountReport', values);

        await store.dispatch(Actions.GET_CUSTOMER_STATMENT_REPORT, values);

        reportData.value = store.getters.getCustomersStatment;

        const doc: any = new jsPDF({
          format: 'a4',
          orientation: 'portrait',
        });
        const fontSize = 10;

        const resTabelBody: any[] = [];

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        let pageCount = 0;
        reportData.value.data.data.forEach(function (element, index) {
          // if (index > 0) {
          //   doc.addPage();
          // }

          // element.lessons.forEach((elementb) => {
          resTabelBody.push([
            //new Date(element['startDate']).toLocaleString('en-GB').split(','),
            index + 1,
            element['customerName'],
            element['slipNo'],
            element['date'],
            element['discount'],
            element['serviceType'],
            element['amount'],
          ]);
          // });
        });
        autoTable(doc, {
          head: [
            [
              'id',
              'Customer',
              'rcpt No',
              'Receipt Date',
              'Discount',
              'Amount Paid For',
              'amount',
            ],
          ],
          body: resTabelBody,
          startY: 45,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          margin: { horizontal: 'auto', top: 45 },
          horizontalPageBreak: false,
          theme: 'grid',
          headStyles: {
            fillColor: [0, 72, 115],
            fontStyle: 'bold',
            halign: 'left',
            fontSize: 8,
          },
          bodyStyles: {
            fontStyle: 'bold',
            halign: 'left',
            fontSize: 8,
          },
          styles: {
            fontSize: 10,
          },
          didDrawPage: function () {
            doc.setFontSize(10);
            pageCount++;
            const pageCountWidth = doc.getTextWidth(String(pageCount));

            doc.text(
              'Page ' + pageCount,
              textX - pageCountWidth + 2,
              doc.internal.pageSize.height - 9
            );
            doc.setFontSize(fontSize);
          },
        });
        const logoImage = await loadImage('/media/logos/empty-krc.png');

        Array.from({ length: pageCount }, (x, i) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.addImage(logoImage, 'png', 5, 3, 35, 35);
          doc.setFontSize(14);
          doc.setFont('helvetica', 'bold');
          doc.text('Kuwait Riding Center', textX, 15);
          doc.setFontSize(12);
          doc.text('Daily Statement Report', textX, 20);
          doc.setFontSize(10);
          doc.text(
            'Start Date: ' + moment(values.startDate).format('LLL'),
            textX - 30,
            30
          );
          doc.text(
            'End Date: ' + moment(values.endDate).format('LLL'),
            textX + 40,
            30
          );

          doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 5, 8);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore

          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 9);
          doc.setFont('helvetica', 'normal');

          doc.text(
            '     of ' + doc.getNumberOfPages(),
            textX + 10,
            doc.internal.pageSize.height - 9
          );

          if (i + 1 === pageCount) {
            if (reportData.value.data.totalAmount) {
              doc.text('Total:', 20, doc.previousAutoTable.finalY + 5);
              doc.text(
                String(reportData.value.data.totalAmount.toFixed(3)),
                '170',
                doc.previousAutoTable.finalY + 5
              );
            }
          }

          doc.setFontSize(fontSize);
        });

        // doc.save('table.pdf');
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');

        window.open(blobUrl, '_blank');
        // printJs({ printable: blobUrl, type: 'pdf' });
      };

      const validationSchema = Yup.object().shape({});
      // const onSubmitCreate = async (values) => {
      //   debugger;
      //   if (submitButton.value) {
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = true;
      //     submitButton.value.setAttribute('data-kt-indicator', 'on');
      //   }
      //   values.duration = Number(values.duration);
      //   values.numberOfRider = Number(values.numberOfRider);

      //   const { data } = await store.dispatch(
      //     Actions.GET_CUSTOMER_ATTENDANCE,
      //     values
      //   );
      //   const [errorName] = Object.keys(store.getters.getErrors);
      //   const error = store.getters.getErrors[errorName];

      //   if (error) {
      //     Swal.fire({
      //       text: translate(error[0]),
      //       icon: 'error',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('tryAgainExcl'),
      //       customClass: {
      //         confirmButton: 'btn fw-bold btn-light-danger',
      //       },
      //     });
      //     //Deactivate indicator
      //     submitButton.value?.removeAttribute('data-kt-indicator');
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = false;
      //   } else {
      //     Swal.fire({
      //       text: translate('SUCCESSFULLY_CREATED_CLASS_CATEGORY'),
      //       icon: 'success',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('ok'),
      //       customClass: {
      //         confirmButton: 'btn btn-light-primary',
      //       },
      //     }).then(() => {
      //       router.push({ name: 'class-category-listing' });
      //     });
      //   }
      // };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.DAYS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.DURATION,
        LookupNameIds.STATUS,
        LookupNameIds.ATTENDEND,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.ATTENDEND;
      });

      // const categoryList = await store.dispatch(
      //   Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      // );
      const classType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const dayList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DAYS;
      });

      const durationList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DURATION;
      });

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        arenas: computed(() => store.getters.allArenasList),
        horses: computed(() => store.getters.allHorsesList),
        groomers: computed(() => store.getters.allGroomerList),
        statusList,
        classType,
        // categoryList,
        dayList,
        durationList,
        selectedCustomer,
        selectedTrainer,
        selectedArena,
        selectedHorse,
        selecteDuration,
        selectedAttendance,
        selecteType,
        selectedDay,
        selectedGroomer,
        isPaid,
        startDate,
        endDate,
        loadingPage,
        clearSearch,
        balanceTo,
        balanceFrom,
      };
    },
  });
